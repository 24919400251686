import React from 'react';
import { connect } from 'react-redux';
import { generateSharableReportLink } from 'actions/reports/apiActions';
import LinkedInShareCertificate from 'commonComponents/linkedInShareCertificate';
import FetchLinkedinShareUrl from 'actions/serverCalls/reports/FetchLinkedinShareUrl';

class LinkedInShareCertificateContainer extends React.Component {
	fetchLinkedinShareUrl = (payload) => {
		return FetchLinkedinShareUrl.call();
	}
	render() {
		return <LinkedInShareCertificate
			{...this.props}
			fetchLinkedinShareUrl={this.fetchLinkedinShareUrl}
		/>;
	}
}

const mapStateToProps = (state) => ({
	user: state.get('user'),
	reports: state.get('reports')
});

const mapDispatchToProps = (dispatch) => ({
	generateSharableReportLink: (payload) => {
		generateSharableReportLink(payload)
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkedInShareCertificateContainer);