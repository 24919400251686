import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';

class FetchLinkedinShareUrl extends NetPackWrapper {

	constructor() {
		super('GET', true);
	}

	prepareUrl = () => {
		return urls.getIn(['REPORT', 'GET_LINKEDIN_SHARE_URL']);
	};

	successCall = (response, apiInfoObject) => {
		return response;
	}

	onError = (errorObj) => {
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Sorry, unable to generate your report/certificate. Please try again!',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
	}
}

export default new FetchLinkedinShareUrl();
