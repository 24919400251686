import React, { Component, PureComponent } from 'react';
import CSSModules from 'react-css-modules';
import Immutable from 'immutable';
import updateRoute from 'utils/webAppRoutes/updateRoute';

import Video from 'components/moduleItems/video';
import WikiPage from 'components/moduleItems/wikiPage';
import Document from 'components/moduleItems/document';
import File from 'components/moduleItems/file';
import Simulation from 'components/moduleItems/simulation';
import ScormContent from 'components/moduleItems/scormContent';
import WebSession from 'components/moduleItems/webSession';
import ClassroomSession from 'components/moduleItems/classroomSession';
import ExternalUrl from 'components/moduleItems/externalUrl';
import DigitalToolContainer from 'containers/DigitalToolsContainer';
import Quiz from 'containers/QuizContainer';
import ModuleItemHeader from '../moduleItemHeader';
import ModuleItemTitle from '../moduleItemTitle';
import NextItem from 'commonComponents/nextItem';
import ModuleItemLoader from './ModuleItemLoader';
import Topics from 'components/moduleItems/topics';

import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import styles from './moduleItem.module.sass';

import routes from 'utils/webAppRoutes/routeConstants';

import Assignment from '../assignment';
import Footer from 'commonComponents/footer';

import ContentNotSupportedErrorTemplate from 'commonComponents/errorTemplate/ContentNotSupportedErrorTemplate';
import ContextModuleProgression from 'utils/moduleItem/contextModuleProgression';
import ModuleItemFeedbackContainer from 'containers/ModuleItemFeedbackContainer'

import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

import { getLockMessage } from 'utils/locks';
import ModuleItemUtil from 'utils/moduleItem/moduleItem';
import ThirdPartyCookieCheck from 'commonComponents/thirdPartyCookieCheck';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ModuleItem extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			keepFeedbackWidgetOpen: false
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.moduleItem.getIn(['moduleItem', 'completion_requirement', 'completed']) === false && nextProps.moduleItem.getIn(['moduleItem', 'completion_requirement', 'completed'])) {
			this.setState({
				keepFeedbackWidgetOpen: true
			});
		}
	}

	renderContentComponent = (moduleItem) => {
		const { isSidebarOpen } = this.props;
		const {
			match,
			tools,
			fetchProductLaunch,
			unsetProductLaunch,
			updateMediaFileStatus,
			markModuleItemRead,
			submitAssignment,
			developmentProductLaunchError,
			uploadFile,
			phaseItemData,
			markModuleItemDone
		} = this.props;
		

		const lockDetails = ModuleItemUtil.getModuleItemLockDetails(moduleItem.get('moduleItem'), phaseItemData);

		try {
			if (lockDetails.locked || (lockDetails.expired && !lockDetails.completed)) {
				return <div styleName="locked-message">
						{lockDetails.explanation}
					</div>
			}
		} catch (error) {
			console.log('error', error);
		}

		//send next item details to display after feedback submission
		let nextItemDetailsForFeedback = {
			batchId: match.params.batchId,
			nextItem: moduleItem.get('nextModuleItem'),
			onClickOfNext: this.onClickOfNext,
			isLocked: this.isNextItemLocked(),
			isDevelopmentItem: true
		}

		if (moduleItem.get('moduleItem').get('type') == 'ExternalUrl') {
			return <ExternalUrl
				moduleItem={moduleItem}
				markRead={this.markRead}
				match={match}
				nextItemDetailsForFeedback={nextItemDetailsForFeedback}
			/>;
		}

		switch (moduleItem.get('moduleItem').get('type_label')) {
			case 'Page':
				return <WikiPage
					moduleItem={moduleItem}
					markRead={this.markRead}
					match={match}
					nextItemDetailsForFeedback={nextItemDetailsForFeedback}
				>
				</WikiPage>;
			case 'Video':
				return <Video
					moduleItem={moduleItem}
					updateMediaFileStatus={updateMediaFileStatus}
					match={match}
					markRead={this.markRead}
					nextItemDetailsForFeedback={nextItemDetailsForFeedback}
				/>
			case 'Quiz':
				return <Quiz
					key={moduleItem.getIn(['moduleItem', 'id'])}
					moduleItem={moduleItem}
					isSidebarOpen={isSidebarOpen}
					match={match}
					nextItemDetailsForFeedback={nextItemDetailsForFeedback}
					markRead={this.markRead}
					lockDetails={lockDetails}
				/>
			case 'Assignment':
				return <Assignment
					moduleItem={moduleItem}
					submitAssignment={submitAssignment}
					match={match}
					nextItemDetailsForFeedback={nextItemDetailsForFeedback}
					markRead={this.markRead}
					uploadFile={uploadFile}
					removeFile={this.props.removeFile}
					lockDetails={lockDetails}
				/>;
			case 'Document':
			case 'PDF':
			case 'PPT':
			case 'Presentation':
				return <Document
					moduleItem={moduleItem}
					updateMediaFileStatus={updateMediaFileStatus}
					match={match}
					markModuleItemRead={markModuleItemRead}
					nextItemDetailsForFeedback={nextItemDetailsForFeedback}
				/>
			case 'File':
			case 'Image':
				return <File
					moduleItem={moduleItem}
					markRead={this.markRead}
					match={match}
					nextItemDetailsForFeedback={nextItemDetailsForFeedback}
				/>;
			case 'Classroom Session':
				return <ClassroomSession
					moduleItem={moduleItem}
					isSidebarOpen={isSidebarOpen}
					match={match}
					nextItemDetailsForFeedback={nextItemDetailsForFeedback}
				/>
			case 'Web Session':
				return <WebSession
					moduleItem={moduleItem}
					isSidebarOpen={isSidebarOpen}
					match={match}
					nextItemDetailsForFeedback={nextItemDetailsForFeedback}
					markModuleItemDone={markModuleItemDone}
				/>
			case 'Digital Tool':
				return <DigitalToolContainer
					key={moduleItem.getIn(['moduleItem', 'id'])}
					moduleItem={moduleItem}
					isSidebarOpen={isSidebarOpen}
					match={match}
				/>
			case 'Simulation':
				return <Simulation
					moduleItem={moduleItem}
					tools={tools}
					match={match}
					isSidebarOpen={isSidebarOpen}
					fetchProductLaunch={() => fetchProductLaunch(match.params)}
					unsetProductLaunch={unsetProductLaunch}
					nextItemDetailsForFeedback={nextItemDetailsForFeedback}
					launchError={developmentProductLaunchError}
					markRead={this.markRead}
				/>
			case 'Scorm':
			case 'SCORM':
			case 'Content':
			case 'SCORM Content':
				return <ScormContent
					moduleItem={moduleItem}
					fetchProductLaunch={() => fetchProductLaunch(match.params)}
					unsetProductLaunch={unsetProductLaunch}
					markRead={this.markRead}
					match={match}
					nextItemDetailsForFeedback={nextItemDetailsForFeedback}
					launchError={developmentProductLaunchError}
					userState={this.props.userState}
				/>
			case 'ExternalUrl':
				return <ExternalUrl
					moduleItem={moduleItem}
					markRead={this.markRead}
					match={match}
					nextItemDetailsForFeedback={nextItemDetailsForFeedback}
				/>
			default:
				return <ContentNotSupportedErrorTemplate />
		}
	}

	markRead = () => {
		const { moduleItem } = this.props;
		const isCompleted = ContextModuleProgression.isCompleted(moduleItem.get('moduleItem'));
		const isLocked = moduleItem.get('moduleItem').get('lock_details').get('locked');
		
		if (!isCompleted && !isLocked) {
			this.props.markModuleItemRead(this.props.match.params);
		}
	};

	isNextItemLocked = () => {
		const { moduleItem } = this.props;
		try {
			return moduleItem.get('nextModuleItem').get('lock_details').get('locked');
		} catch (error) {
			return false;
		}
	};

	getNextItemLockMessage = () => {
		const {moduleItem} = this.props;
		try{
			return getLockMessage(moduleItem.get('nextModuleItem').get('lock_details'))
		}catch(e){
			return null;
		}
	}

	onClickOfNext = (event) => {
		const { moduleItem, match, phaseItemData } = this.props;

		let nextItemLockDetails = {locked: false, completed: false, expired: false, explanation: ''};
		if(moduleItem && phaseItemData){
			nextItemLockDetails = ModuleItemUtil.getModuleItemLockDetails(moduleItem.get('nextModuleItem'), phaseItemData || Immutable.fromJS({}));
		}
		if (nextItemLockDetails.locked || (nextItemLockDetails.expired && !nextItemLockDetails.completed)) {
			return;
		}

		const nextItemId = moduleItem.get('nextModuleItem').get('id');
		const moduleId = moduleItem.get('nextModuleItem').get('module_id');
		const courseId = match.params.courseId;
		const batchId = match.params.batchId;
		const phaseId = match.params.phaseId;
		const phaseItemId = match.params.phaseItemId;
		const route = routes.get('BATCH_ITEM_MODULE');
		const params = [
			{ 'BATCH_ID': batchId },
			{ 'COURSE_ID': courseId },
			{ 'MODULE_ID': moduleId },
			{ 'ITEM_ID': nextItemId },
			{ '_PHASE_ID_': phaseId },
			{ '_PHASEITEMID_': phaseItemId }
		];
		updateRoute({ route, params });
	};

	shouldShowFeedback = (moduleItem) => {

		const lockDetails = ModuleItemUtil.getModuleItemLockDetails(moduleItem.get('moduleItem'), this.props.phaseItemData);


		if (lockDetails.locked || (lockDetails.expired && !lockDetails.completed)) return false;

		return moduleItem.getIn(['moduleItem', 'feedback_enabled']) &&
			moduleItem.getIn(['moduleItem', 'completion_requirement', 'completed']);
	}

	hasModuleItemLoaded = (moduleItem) => {
		if(moduleItem.get('moduleItem').size > 0){

			if(moduleItem.get('moduleItem').get('type') == 'ExternalUrl') return true;

			if(moduleItem.get('contentDetails').size > 0 && moduleItem.get('areContentDetailsAvailable')){
				return moduleItem.get('moduleItem').get('content_id') == moduleItem.get('contentDetails').get('id');
			}
		}
		return false;
	}

	renderThirdPartyCheck = (moduleItem) => {

		if(moduleItem.get('moduleItem').get('type_label') == 'Content' || moduleItem.get('moduleItem').get('type_label') == 'Simulation'){
			return <ThirdPartyCookieCheck/>
		}

		return null;		
	}


	renderModuleItemComponent = () => {
		let { moduleItem, match, quiz, isScormCourse, fetchModuleItemError, fetchModuleItem } = this.props;
		return <APIErrorBoundary
			key={match.params.moduleItemId}
			apiError={fetchModuleItemError}
			retryHandler={() => fetchModuleItem(match.params)}
		>
			<span>
				{ this.hasModuleItemLoaded(moduleItem) ?
					<div>
						<ModuleItemHeader
							moduleItem={moduleItem.get('moduleItem')}
							batchId={match.params.batchId}
							isScormCourse={isScormCourse}
						/>
						<ModuleItemTitle
							title={moduleItem.get('moduleItem').get('title')}
							subTitle={moduleItem.get('moduleItem').get('subtitle')}
							completed={false}
							moduleItem={moduleItem}
							maxPoints={moduleItem.get('moduleItem').get('content_details') ? moduleItem.get('moduleItem').get('content_details').get('points_possible') : null}
							pointsEarned={null}
							quiz={quiz}
							match={match}
						/>
						{this.renderThirdPartyCheck(moduleItem)}
						{this.renderContentComponent(moduleItem)}
						{
							this.shouldShowFeedback(moduleItem) &&
							<ModuleItemFeedbackContainer
								match={this.props.match}
								keepFeedbackOpen={this.state.keepFeedbackWidgetOpen}

							/>
						}
						<Topics moduleItem={moduleItem} />
					</div>
					:
					<ModuleItemLoader />
				}
			</span>
		</APIErrorBoundary>;
	}

	render() {
		let { moduleItem, match, isSidebarOpen, phaseItemData } = this.props;
		let moduleItemStyle = "";
		let nextItemLockDetails = {locked: false, completed: false, expired: false, explanation: ''};
		if (isSidebarOpen) {
			moduleItemStyle = "sidebar-open";
		}

		if(moduleItem && phaseItemData){
			nextItemLockDetails = ModuleItemUtil.getModuleItemLockDetails(moduleItem.get('nextModuleItem'), phaseItemData || Immutable.fromJS({}));
			}

		return (
			[
				<div key="module-item-content" className={moduleItemStyle} styleName={`${moduleItemStyle} module-item-inner-cnt`}>
					{
						this.renderModuleItemComponent()
					}
				</div>,

				<div key="module-item-next-item" styleName="bottom" className={isSidebarOpen && 'module-item-bottom-sidebar-open'}>
					<NextItem
						batchId={match.params.batchId}
						phaseItemId={match.params.phaseItemId}
						courseId={match.params.courseId}
						phaseItemData={phaseItemData}
						nextItem={moduleItem.get('nextModuleItem')}
						onClickOfNext={this.onClickOfNext}
						lockMessage={nextItemLockDetails.explanation}
						isLocked={nextItemLockDetails.locked || (nextItemLockDetails.expired && !nextItemLockDetails.completed)}
						isDevelopmentItem={true}
						match={match}
					/>
					{this.props.renderDialog()}
					<Footer />
				</div>
			]
		)
	}
}

export default ModuleItem;