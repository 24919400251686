import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './linkedInShareCertificate.module.sass';
import applyLabel from 'utils/LabelProvider';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite';
import { checkIfPresent } from 'utils/utilFunctions';
import LinkedInLogo from 'svgComponents/linkedInLogo';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class LinkedInShareCertificate extends Component {

	onShareOnLinkedInClick = async () => {
		const { getLabel, fetchLinkedinShareUrl } = this.props;
		const linkedInShareUrl = (await fetchLinkedinShareUrl()).textResponse;
		if (linkedInShareUrl) {
			const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=500,left=0,top=0`;	
			let newWindow = window.open(linkedInShareUrl, 'Aktivlearn Plus', params);
			if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
				alert(getLabel('new_window_blocked_message'));
			}
		}
	}

	getShareOnLinkedInButton = (myStyles) => {
		const {
			reports,
			metadata,
			getLabel
		} = this.props;

		if (
			reports.get(`linked_in_share_certificate_batch_${metadata.batchId}`)
			&& !checkIfPresent(reports.getIn([`linked_in_share_certificate_batch_${metadata.batchId}`, 'task', 'certificateLink']))
		) {
			return (
				<div styleName="wait-msg-cnt">
					{getLabel('please_wait_label')}
				</div>
			)
		}

		return (
			<div styleName="share-on-linked-button" onClick={this.onShareOnLinkedInClick}  className={css(myStyles.button)}>
				<div styleName="linked-in-logo">
					<LinkedInLogo />
				</div>
				<div className={css(myStyles.shareText)}>
					{getLabel('share_on_liked_in_label')}
				</div>
			</div>
		)
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);

		return this.getShareOnLinkedInButton(myStyles);
	}
}

export default LinkedInShareCertificate;